import React, { useEffect } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import "./styles/home.scss";

// COMPONENT
import Navigation from "partial/Navigation";
import Title from "./components/Title";
import Content from "./components/Content";
import { change_bg, disableButton, put_data } from "redux/actions/main";

const Home = ({ match }) => {
  const dispatch = useDispatch();
  const { prize, json, is_play } = useSelector(
    (state) => state.main,
    shallowEqual
  );
  const id = match.params?.id;
  const object = prize?.filter(obj => String(obj.name).toLowerCase() === String(id).toLowerCase())?.[0]
  const current_pool = object
  
  useEffect(() => {
    dispatch(change_bg(id));
    dispatch(disableButton(!current_pool));
  }, [dispatch, id, current_pool]);

  const goToPlay = () => {
    if(is_play)  {      
      document.getElementById("myAudio").pause()      
      dispatch(put_data("is_play", false))
    } else {
      document.getElementById("myAudio").play()      
      dispatch(put_data("is_play", true))
    }
  }
  
  return (
    <div className="home">
      <Navigation current_pool={current_pool}/>
      <Title />
      <Content current_pool={current_pool}/>
      <div className="btn-play-music">
        <img src={is_play? json.img_music_btn : json.img_music_btn_off} alt="" onClick={goToPlay} />
      </div>
    </div>
  );
};

export default Home;
