import React from "react";

const DEFAULT_TOKEN = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/header_token.png";
const DEFAULT_VOUCHER = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/header_voucher.png";
const DEFAULT_USERNAME = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/header_username.png";

const User = ({
	ticket = 0,
	voucher = 0,
	nickname = "Guest",
	img_header_token,
	img_header_voucher,
	img_header_username,
	text_color_universal,
	img_rope
}) => {
	const style = {
		"--rope": `url(${img_rope})`
	};
	return (
		<div className="user-details">
			<div className="user token" style={style}>
				<div className="wrapper">
					<img src={img_header_token ?? DEFAULT_TOKEN} alt="" />
					<span>{ticket}</span>
				</div>
			</div>
			<div className="user voucher" style={style}>
				<div className="wrapper">
					<img src={img_header_voucher ?? DEFAULT_VOUCHER} alt="" />
					<span>{voucher}</span>
				</div>
			</div>
			<div className="user name" style={style}>
				<div className="wrapper">
					<img src={img_header_username ?? DEFAULT_USERNAME} alt="" />
					<span style={{ "--universal-color": text_color_universal }}>            
						Hi, {nickname === "Guest"? nickname : decodeURIComponent(nickname)}!
					</span>
				</div>
			</div>
		</div>
	);
};

export default User;
