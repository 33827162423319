import React, { useState } from "react"

const Confirm = (props) => {
	const [skip, setskip] = useState(false)

	return (
		<>
			<div className="content">
				<div className="question">
					<span>
						Use {props?.draw_price} <img src={props?.img_token} alt="" /> for 1 draw?
					</span>
				</div>
				<label htmlFor="confirm_draw">
					<input onClick={() => setskip(!skip)} type="checkbox" name="confirm_draw" id="confirm_draw" />
					Don't ask again
				</label>
				<img
					className="button"
					onClick={() => {
						if (skip) window.localStorage.setItem("skip_confirm", true)
						props?.draw()
					}}
					src={props?.img_ok_btn}
					alt=""
				/>
			</div>
		</>
	)
}

export default Confirm
