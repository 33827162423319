import axios from "axios"

const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
	transformRequest: [
		(data, headers) => {
			delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
			return data
		},
	],
})

const cdn_err = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
	withCredentials: false,
	transformRequest: [
		(data, headers) => {
			delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
			return data
		},
	],
})

export const toggle_popup = (bool, tipe, data = false) => ({
	type: "TOGGLE_POPUP",
	bool,
	tipe,
	data,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

export const add_pool_data = (key, data) => ({
	type: "ADD_POOL_DATA",
	key,
	data,
})

export const put_data_hash = (key, data) => ({
	type: "PUT_DATA_HASH",
	key,
	data,
})

export const change_bg = (id) => {
	return (_, getState) => {
		const { main } = getState()
		const body = document.querySelector("#Main"),
			filter = main?.prize?.filter((dt) => id === dt.name.toLowerCase()),
			bg = filter?.[0]?.background ?? ""

		// index = main?.json?.navigation?.findIndex(
		//   (dt) => id === dt.name.toLowerCase()
		//   ),

		body.style.backgroundImage = `url(${bg})`
		// dispatch(put_data("current_pool", main?.user?.pool_ids?.[index]));
	}
}

export const get_json = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		const directus_req = cdn.get(`data.json?${Math.random() * 10000}`)
		const general_err = cdn_err.get(`${"EN"}.json?${Math.floor(Math.random() * 10000)}`)

		axios
			.all([general_err, directus_req])
			.then((resp) => {
				const json = {
					...resp[0].data,
					...resp[1].data,
				}
				dispatch(put_data("json", json))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const check_login = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("/oauth/check_login/")
			.then((resp) => {
				dispatch(put_data("user", resp.data))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_pool = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get(`/api/pool/`)
			.then((resp) => {
				dispatch(put_data("prize", resp.data))
				resp?.data?.map((e) => dispatch(get_draw_pool_data(e.id, e.name)))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_draw_pool_data = (pool, name) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get(`/api/pool/${pool}/`)
			.then((resp) => {
				dispatch(add_pool_data(String(name).toLowerCase(), resp.data))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_history = () => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		axios
			.get("/api/history/")
			.then((resp) => {
				dispatch(put_data("history", resp.data))
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const post_discount = (history) => {
	return (dispatch, getState) => {
		const { main } = getState()
		dispatch(toggle_loader(true))
		axios
			.post("/api/discount/")
			.then((resp) => {
				dispatch(put_data("discount", resp.data))
				dispatch(check_login())
				setTimeout(() => {
					if (!main?.is_play) {
						document.getElementById("myAudio").play()
						dispatch(put_data("is_play", true))
					}
					history.push("/")
				}, 2000)
			})
			.catch((err) => {
				if (err?.response?.data?.msg === "discount_generated") {
					if (!main?.is_play) {
						document.getElementById("myAudio").play()
						dispatch(put_data("is_play", true))
					}
					history.push("/")
				} else {
					dispatch(catch_error(err?.response?.data?.msg))
				}
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const top_up = (_, toggle_confirm, toggle_draw) => {
	return (dispatch) => {
		dispatch(toggle_loader(true))

		const skip_confirm = window.localStorage.getItem("skip_confirm")
		if (!skip_confirm) {
			setTimeout(() => {
				toggle_confirm()
				dispatch(toggle_loader(false))
			}, 1000)
		} else {
			setTimeout(() => {
				toggle_draw()
				dispatch(toggle_loader(false))
			}, 1000)
		}

		// const body = new FormData()
		// body.append('amount', ticket)

		// axios
		//   .post("/api/topup/", body)
		//   .then(() => {
		//     dispatch(check_login())
		// const skip_confirm = window.localStorage.getItem("skip_confirm");
		// if(!skip_confirm){
		//   setTimeout(() => {
		//     toggle_confirm()
		//     dispatch(toggle_loader(false));
		//   }, 1000)
		// }
		// else {
		//   setTimeout(() => {
		//     toggle_draw()
		//     dispatch(toggle_loader(false));
		//   }, 1000)
		// }
		//   })
		//   .catch((err) => {
		//     dispatch(catch_error(err?.response?.data?.msg));
		//     dispatch(toggle_loader(false));
		//   })
	}
}

export const post_draw = (chosenDraws, pool) => {
	return (dispatch, getState) => {
		const speed = 200, // ANIMATION SPEED
			duration = 2000, // DURATION OF THE ANIMATION
			delay = 1000, // DELAY POPUP OPEN
			highlight_delay = 100 // DELAY HIGHLIGHT SO IT WOULDN'T INTERRUPT

		const filtered = chosenDraws?.filter((dt) => !dt.is_claimed)
		const { main } = getState()
		// const discount_used = window.localStorage.getItem("discount_used");
		const discount_used = main?.discount_used
		dispatch(toggle_loader(true))
		dispatch(disableButton(true))

		const body = new FormData()
		body.append("pool", pool?.id)
		if (discount_used) {
			// console.log("main ", main)
			// const find = main?.user?.user?.discount_used.find((disc) => disc === discount_used)
			// if(!find) {
			//   body.append("discount", discount_used);
			// }
			body.append("discount", discount_used)
		} else {
			body.delete("discount")
		}

		axios
			.post(`/api/draw/`, body)
			.then((resp) => {
				// DETERMINE ELEMENT FOR FINAL PRIZE
				const el = document.querySelector(`.prize-${resp?.data?.position} .item-wrapper`)
				if (filtered.length === 1) {
					dispatch(showPopup(el, resp.data))
					dispatch(check_login())
					dispatch(get_history())
					dispatch(get_draw_pool_data(pool?.id, pool?.name))
					dispatch(disableButton(false))
				} else {
					// START ANIMATION WITH INTERVAL
					const animate = setInterval(() => animate_draw(speed, filtered), speed)

					// STOP ANIMATION
					setTimeout(() => {
						clearInterval(animate)
					}, duration)

					// HIGHLIGHT FINAL PRIZE
					setTimeout(() => {
						el?.classList.add("active")
					}, duration + speed + highlight_delay)

					setTimeout(() => {
						clearInterval(animate)
					}, duration)

					// SHOW POPUP SUCCESS
					setTimeout(() => {
						dispatch(showPopup(el, resp.data))
						el?.classList.remove("active")
						dispatch(check_login())
						dispatch(get_history())
						dispatch(get_draw_pool_data(pool?.id, pool?.name))
						dispatch(disableButton(false))
					}, duration + speed + highlight_delay + delay)
				}
			})
			.catch((err) => {
				dispatch(catch_error(err?.response?.data?.msg))
				dispatch(disableButton(false))
			})
			.then(() => {
				dispatch(put_data("discount_used", false))
				dispatch(toggle_loader(false))
				// window.localStorage.removeItem("discount_used");
			})
	}
}

const animate_draw = (speed, data) => {
	const random = getRndInteger(0, data?.length)
	const el = document.querySelector(`.prize-${data[random].position} .item-wrapper`)

	el?.classList.add("active")
	setTimeout(() => {
		el?.classList.remove("active")
	}, speed)
}

const showPopup = (el, data) => {
	return (dispatch) => {
		dispatch(
			toggle_popup(true, "success", {
				...data,
				close: () => {
					dispatch(put_data("popup_open", false))
				},
			})
		)
		// REMOVE HIGHLIGHT
		// el?.classList.remove("active");
		// REMOVE BUTTON DISABLE
		// dispatch(disableButton(false));
	}
}

const getRndInteger = (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min
}

export const disableButton = (toggle) => {
	return () => {
		const buttons = document.querySelector(".button-container")
		const navigations = document.querySelector(".navigation-cont")
		if (toggle) {
			buttons?.classList.add("disabled")
			navigations?.classList.add("disabled")
		} else {
			buttons?.classList.remove("disabled")
			navigations?.classList.remove("disabled")
		}
	}
}

export const catch_error = (err_msg) => {
	return (dispatch) => {
		if (err_msg) {
			dispatch(put_data("err_msg", err_msg))
		}
		dispatch(toggle_popup(true, "error"))
		dispatch(toggle_loader(false))
	}
}
