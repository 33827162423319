import React from "react";
import { useDispatch } from "react-redux";
import { put_data, post_draw, toggle_popup } from "redux/actions/main";

const DEFAULT_DRAW = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/button_draw.png";
const DEFAULT_HISTORY = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/button_history_small.png";
const DEFAULT_RULES = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/button_details_small.png";

const Button = ({
	user,
	priceInVoucher,
	priceInTicket,
	current_pool,
	discount_used,
	chosenDraws,
	img_draw_button,
	img_history_button,
	img_rules_button,
	img_notif
}) => {
	const dispatch = useDispatch();

	const ticket = user?.ticket;
	const voucher = user?.voucher;
	const multiplier = user?.ticket_to_voc;
	const discount = user ? user.discount : [];
	// const discount_used = window.localStorage.getItem("discount_used");
	let old_price = priceInTicket;

	if (discount_used) {
		priceInTicket = Math.ceil(priceInTicket * (1 - (discount_used / 100)));
		priceInVoucher = Math.ceil(priceInVoucher * (1 - (discount_used / 100)))
	}
	// console.log(voucher, priceInVoucher, old_price, priceInTicket, discount_used);
	const is_valid_pool = chosenDraws?.filter((draw) => !draw?.is_claimed)?.length > 0;

	const handleDraw = () => {
		const skip_confirm = window.localStorage.getItem("skip_confirm");

		if (skip_confirm) {
			dispatch(post_draw(chosenDraws, current_pool));
		} else {
			if (ticket >= priceInTicket) {
				dispatch(
					toggle_popup(true, "confirm", {
						draw: () => {
							dispatch(put_data("popup_open", false));
							dispatch(post_draw(chosenDraws, current_pool));
						},
						draw_price: priceInTicket
					})
				);
			} else if (ticket < priceInTicket && voucher >= priceInVoucher) {
				dispatch(
					toggle_popup(true, "confirm-left", {
						draw: () => {
							dispatch(put_data("popup_open", false));
							dispatch(post_draw(chosenDraws, current_pool));
						},
						priceInVoucher: multiplier * (priceInTicket - ticket),
						priceInTicket: priceInTicket - ticket
					})
				);
			} else {
				dispatch(put_data("err_msg", "insufficient_voucher"));
				dispatch(toggle_popup(true, "error"));
			}
		}
		return;
	};

	return (
		<div className={`button-container`}>
			<div className="buttons">
				<div className="edge">
					<img
						onClick={() => {
							dispatch(toggle_popup(true, "info"));
						}}
						src={img_history_button ?? DEFAULT_HISTORY}
						alt=""
					/>
				</div>
				<div className={`center ${is_valid_pool ? "" : "disabled"}`}>
					<img src={img_draw_button ?? DEFAULT_DRAW} onClick={handleDraw} alt="" />
					<div className="txt-price">
						{
							old_price !== priceInTicket &&
							<span className="discount-price">
								{old_price}
							</span>
						}
						<span>{priceInTicket}</span>
					</div>
				</div>
				<div
					className="edge"
					onClick={() => {
						dispatch(toggle_popup(true, "discount"));
					}}
				>
					<img
						src={img_rules_button ?? DEFAULT_RULES}
						alt=""
					/>
					<div className="notif">
						<img src={img_notif} alt="" />
						<p className="number">{discount.length}</p>
					</div>
					<p className="discount-price">-%</p>
				</div>
			</div>
		</div>
	);
};

export default Button;
