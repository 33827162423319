import React from "react";
import { useDispatch } from "react-redux";
import { put_data } from "redux/actions/main";

const ConfirmDiscount = ({ img_ok_btn, data, close }) => {
  const dispatch = useDispatch();
  const handleUseDisc = () => {
    // window.localStorage.setItem("discount_used", data);
    dispatch(put_data("discount_used", data));
    close();
  };

  return (
    <>
      <div className="content">
        <div className="question">
          <p>Use discount on your next draw?</p>
          <span>*only can used once</span>
        </div>
        <img
          className="button"
          src={img_ok_btn}
          alt=""
          onClick={handleUseDisc}
        />
      </div>
    </>
  );
};

export default ConfirmDiscount;
