import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { sendDebug } from "helper";

const Title = () => {
	const { json } = useSelector((state) => state.main, shallowEqual);

	return (
		<div className="title-container" onTouchStart={sendDebug}>
			<img src={json.img_title} alt="" />
		</div>
	);
};

export default Title;
