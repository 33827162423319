export const checkLogin = {
    "user": {
        "uid": 332076105,
        "open_id": "39c4211c5075401b89b9c7b1d684c92c",
        "t_open_id": "153965518783645",
        "avatar": "https://f.gxx.garenanow.com/download/09f73c2805164a81936d5bb8b840df6704030100000057fa00000000020100ec_tn",
        "nickname": "Morgania",
        "event_id": 4,
        "owned": [],
        "voucher": 2600,
        "ticket": 0,
        "ticket_to_voc": 1,
        "discount_generated": false,
        "id": 252821,
        "t_open": "153965518783645",
        "tickets": 0,
        "discount": [],
        "discount_used": [
            50
        ],
        "discount_applied": 0
    },
    "access_token": "e02edb1883c5313fce11446a766548be9f05fee0396ca885127da3a97b0235b9"
}

export const checkLoginNotGenerated = {
    "user": {
        "uid": 315597571,
        "open_id": "ada2198d9acfb85b1a3ced3a05ef3933",
        "t_open_id": "68121000805533",
        "avatar": "https://cdngarenanow-a.akamaihd.net/mgames/kgid/images//hok/H5_PSS/Avatar/462.png",
        "nickname": "ZednaX",
        "event_id": 4,
        "owned": [],
        "voucher": 4,
        "ticket": 30,
        "ticket_to_voc": 1,
        "discount_generated": false,
        "id": 263297,
        "t_open": "68121000805533",
        "tickets": 0,
        "discount": [],
        "discount_used": []
    },
    "access_token": "1b60d708288a121c5f0ba601d653f67f3a211c8770a50ca1fa152f62e21c2f4c"
}

export const pool = [
    {
        "id": 1,
        "name": "Omen Full Moon Party",
        "thumbnail": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Omen_on.png",
        "thumbnail2": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_omen_off.png",
        "background": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/BG_Omen.jpg"
    },
    {
        "id": 2,
        "name": "Florentino S-Dreamer",
        "thumbnail": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Floren_on.png",
        "thumbnail2": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Floren_off.png",
        "background": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/BG_Floren.jpg"
    },
    {
        "id": 3,
        "name": "Laville S-Dreamer",
        "thumbnail": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Laville_on.png",
        "thumbnail2": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Laville_off.png",
        "background": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/BG_Laville.jpg"
    },
    {
        "id": 4,
        "name": "Zata S-Dreamer",
        "thumbnail": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Zata_on.png",
        "thumbnail2": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/tab_Zata_off.png",
        "background": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/H5/ShrineOfLuck/140423/BG_Zata.jpg"
    }
]

export const history = [
    {
        "purchased_at": "2023-04-14T10:44:44.395977",
        "item_name": "No Star Drop",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:44:51.331340",
        "item_name": "1 Crystal",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:00.315865",
        "item_name": "Florentino's Urban Dance",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:11.683286",
        "item_name": "5 Hero Fragment",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:18.527457",
        "item_name": "Worried Zata",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:35.115178",
        "item_name": "5 Skin Fragment",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:47.583199",
        "item_name": "5 Skin Fragment",
        "pool": "Laville S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:45:54.566910",
        "item_name": "No Star Drop",
        "pool": "Laville S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:56:41.695127",
        "item_name": "Florentino S-Dreamer",
        "pool": "Florentino S-Dreamer"
    },
    {
        "purchased_at": "2023-04-14T10:59:41.119635",
        "item_name": "5 Skin Fragment",
        "pool": "Omen Full Moon Party"
    }
]

export const poolid = [
    {
        "draw_count": 2,
        "draw_left": 7,
        "draw_price": 25,
        "loot": [
            {
                "item_name": "Omen Full Moon Party",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/all_hero_skin/128x128/Omen_Frame_128.png",
                "position": 1,
                "is_claimed": false
            },
            {
                "item_name": "Omen Full Moon Party",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/all_hero_skin/128x128/Omen_Frame_128.png",
                "position": 2,
                "is_claimed": false
            },
            {
                "item_name": "Omen's Dance",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11005477.png",
                "position": 3,
                "is_claimed": false
            },
            {
                "item_name": "1000 Arcana Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11001281.png",
                "position": 4,
                "is_claimed": false
            },
            {
                "item_name": "5 Hero Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300000.png",
                "position": 5,
                "is_claimed": false
            },
            {
                "item_name": "5 Skin Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300001.png",
                "position": 6,
                "is_claimed": true
            },
            {
                "item_name": "1 Crystal",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5100002.png",
                "position": 7,
                "is_claimed": false
            },
            {
                "item_name": "No Star Drop",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5500004.png",
                "position": 8,
                "is_claimed": false
            }
        ]
    },
    {
        "draw_count": 8,
        "draw_left": 1,
        "draw_price": 800,
        "loot": [
            {
                "item_name": "Florentino S-Dreamer",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/all_hero_skin/128x128/Floren_Frame_128.png",
                "position": 1,
                "is_claimed": true
            },
            {
                "item_name": "Florentino's Urban Dance",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11006813.png",
                "position": 2,
                "is_claimed": true
            },
            {
                "item_name": "Worried Zata",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11006763.png",
                "position": 3,
                "is_claimed": true
            },
            {
                "item_name": "1000 Arcana Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11001281.png",
                "position": 4,
                "is_claimed": false
            },
            {
                "item_name": "5 Hero Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300000.png",
                "position": 5,
                "is_claimed": true
            },
            {
                "item_name": "5 Skin Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300001.png",
                "position": 6,
                "is_claimed": true
            },
            {
                "item_name": "1 Crystal",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5100002.png",
                "position": 7,
                "is_claimed": true
            },
            {
                "item_name": "No Star Drop",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5500004.png",
                "position": 8,
                "is_claimed": true
            }
        ]
    },
    {
        "draw_count": 3,
        "draw_left": 6,
        "draw_price": 50,
        "loot": [
            {
                "item_name": "Laville S-Dreamer",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/all_hero_skin/128x128/Laville_Frame_128.png",
                "position": 1,
                "is_claimed": false
            },
            {
                "item_name": "Laville's Urban Dance",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11006839.png",
                "position": 2,
                "is_claimed": false
            },
            {
                "item_name": "Funny Laville",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11007450.png",
                "position": 3,
                "is_claimed": false
            },
            {
                "item_name": "1000 Arcana Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11001281.png",
                "position": 4,
                "is_claimed": false
            },
            {
                "item_name": "5 Hero Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300000.png",
                "position": 5,
                "is_claimed": false
            },
            {
                "item_name": "5 Skin Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300001.png",
                "position": 6,
                "is_claimed": true
            },
            {
                "item_name": "1 Crystal",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5100002.png",
                "position": 7,
                "is_claimed": false
            },
            {
                "item_name": "No Star Drop",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5500004.png",
                "position": 8,
                "is_claimed": true
            }
        ]
    },
    {
        "draw_count": 1,
        "draw_left": 8,
        "draw_price": 10,
        "loot": [
            {
                "item_name": "Zata S-Dreamer",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/all_hero_skin/128x128/Zata_Frame_128.png",
                "position": 1,
                "is_claimed": false
            },
            {
                "item_name": "Zata's Dance",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11006764.png",
                "position": 2,
                "is_claimed": false
            },
            {
                "item_name": "Confused Zata",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11006762.png",
                "position": 3,
                "is_claimed": false
            },
            {
                "item_name": "1000 Arcana Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/11001281.png",
                "position": 4,
                "is_claimed": false
            },
            {
                "item_name": "5 Hero Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300000.png",
                "position": 5,
                "is_claimed": false
            },
            {
                "item_name": "5 Skin Fragment",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5300001.png",
                "position": 6,
                "is_claimed": false
            },
            {
                "item_name": "1 Crystal",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5100002.png",
                "position": 7,
                "is_claimed": false
            },
            {
                "item_name": "No Star Drop",
                "is_frame": true,
                "amount": 1,
                "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5500004.png",
                "position": 8,
                "is_claimed": false
            }
        ]
    }
]


export const draw = {
    "item_name": "1 Crystal",
    "amount": 1,
    "image": "https://dl-ops-kgid.obs.ap-southeast-3.myhuaweicloud.com/cdn.garenanow.com/mgames/kgid/images/hok/ID/IconPic/5100002.png",
    "position": 7
}