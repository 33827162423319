import React from "react"
import Particles from "react-particles-js"

const Particle = () => {
	return (
		<Particles
			style={{ position: "absolute" }}
			params={{
				particles: {
					links: {
						enable: false,
					},
					size: {
						value: 2,
					},
					move: {
						direction: "top",
						bounce: false,
						outMode: "out",
					},
					number: {
						value: 100,
					},
				},
			}}
		/>
	)
}

export default Particle
