import React from "react"
import { shallowEqual, useSelector, useDispatch } from "react-redux"
import { Modal } from "react-responsive-modal"

import "./style/modal.scss"
import Info from "./Info"
import History from "./History"
import Confirm from "./Confirm"
import ConfirmLeft from "./ConfirmLeft"
import Topup from "./Topup"
import Success from "./Success"
import Error from "./Error"
import Discount from "./Discount"
import ConfirmDiscount from "./ConfirmDiscount"

import { put_data } from "redux/actions/main"

const Modals = () => {
	const duration = 800
	const dispatch = useDispatch()
	const { popup_open, popup_type, popup_data, json, history, user, err_msg } = useSelector(
		(state) => state.main,
		shallowEqual
	)

	// const popup_type = "info"
	// const popup_open = true
	// const { popup_data, json, history, user, err_msg } = useSelector(
	// 	(state) => state.main,
	// 	shallowEqual
	// );

	const renderContent = () => {
		switch (popup_type) {
			case "info":
				return <Info {...json} historyData={history} completeJson={json} />
			case "discount":
				return <Discount {...user} {...json} />
			case "confirm":
				return <Confirm {...user} {...json} {...popup_data} />
			case "confirm-left":
				return <ConfirmLeft {...user} {...json} {...popup_data} />
			case "confirm-discount":
				return <ConfirmDiscount {...user} {...json} {...popup_data} />
			case "history":
				return <History data={history} {...json} />
			case "success":
				return <Success {...popup_data} {...json} />
			case "top_up":
				return <Topup {...user} {...json} {...popup_data} />
			case "error":
				return <Error json={json} err_msg={err_msg} />
			default:
				return <div />
		}
	}

	const modalStyles = {
		"--color": json?.text_color_universal,
		"--scroll-thumb-color": json?.scroll_thumb_color,
		"--scroll-track-color": json?.scroll_track_color,
	}

	switch (popup_type) {
		case "confirm-discount":
			// case 'error':
			modalStyles["--modal-img"] = `url(${json?.img_modal_confirm_bg})`
			break
		case "confirm":
			// case 'error':
			modalStyles["--modal-img"] = `url(${json?.img_modal_confirm_bg})`
			break
		case "confirm-left":
			// case 'error':
			modalStyles["--modal-img"] = `url(${json?.img_modal_confirm_bg})`
			break
		case "error":
			// case 'error':
			modalStyles["--modal-img"] = `url(${json?.img_modal_confirm_bg})`
			break
		case "success":
			modalStyles["--modal-img"] = `url(${json?.img_modal_success_bg})`
			break
		case "discount":
			modalStyles["--modal-img"] = `url(${json?.img_modal_discount_bg})`
			break
		default:
			modalStyles["--modal-img"] = `url("${json?.img_modal_bg}")`
			break
	}

	const closeStyles = {
		"--close-btn-img": `url("${json?.img_modal_closebtn}")`,
	}

	return (
		<Modal
			styles={{ modal: modalStyles, closeButton: closeStyles }}
			open={popup_open}
			focusTrapped={false}
			onClose={() => {
				dispatch(put_data("popup_open", false))
			}}
			onAnimationEnd={() => {
				if (!popup_open) {
					dispatch(put_data("popup_type", false))
					dispatch(put_data("popup_data", false))
				}
			}}
			classNames={{
				modal: `modal-base ${popup_type}`,
				modalAnimationIn: "fadeIn",
				modalAnimationOut: "fadeOut",
				closeButton: "closeBtn",
			}}
			animationDuration={duration}
			center
		>
			<div className="modal-content">{renderContent()}</div>
		</Modal>
	)
}

export default Modals
