import React from "react";

const DEFAULT_TOKEN = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/token.png";
const HEADER_VOUCHER = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/voucher.png";
const OK = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/btn_ok.png";

const Topup = (props) => {

  return (
    <>
      <div className="content">
        <div className="question">
          <span>Your ticket is not enough.</span>
        </div>
        <div className="question">
          <span>Use {props?.priceInVoucher} </span>
          <span>
            <img src={HEADER_VOUCHER} alt="" />
          </span>
          <span> to buy {props?.priceInTicket} </span>
          <span>
            <img src={DEFAULT_TOKEN} alt="" />        
          </span>
          <span>?</span> 
        </div>
        <img
          className="button"
          onClick={() => props?.top_up()}
          src={OK}
          alt=""
        />
      </div>
    </>
  );
};

export default Topup;
