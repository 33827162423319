import React from "react";

const Error = ({ json, err_msg }) => {
	const mapError = () => {
		return json?.[err_msg] ?? err_msg;
	};
	return (
		<div className="content">
			<p>{mapError()}</p>
		</div>
	);
};

export default Error;
