import React from "react"
import { useDispatch } from "react-redux"
import { toggle_popup } from "redux/actions/main"

const Discount = ({ img_discount_use_btn, user, img_discount_wrapper, img_discount_title }) => {
	const wrapperStyle = {
		"--img-frame": `url(${img_discount_wrapper})`,
	}
	const dispatch = useDispatch()

	const goConfirmDisc = (data) => {
		dispatch(toggle_popup(false, "discount"))
		dispatch(
			toggle_popup(true, "confirm-discount", {
				data,
				close: () => dispatch(toggle_popup(false, "confirm-discount")),
			})
		)
	}

	return (
		<>
			<div className="title">
				<img src={img_discount_title} alt="" />
			</div>
			<div className="content">
				{user?.discount.map((data) => (
					<div className="row">
						<div className="info">
							<div className="image" style={wrapperStyle}>
								{data}%
							</div>
							<div className="message">Discount {data}%</div>
						</div>
						<div className="btn-usediscount" onClick={() => goConfirmDisc(data)}>
							<img src={img_discount_use_btn} alt="" />
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default Discount
