import React, { useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { post_discount, put_data } from 'redux/actions/main';

import "./styles/landingDiscount.scss";

const LandingDiscount = () => {
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(false)
  const history = useHistory()
  const { json, user: { user }, is_play } = useSelector((state) => state.main, shallowEqual);
  
  const getDiscount = () => {
    setDisabled(true);    
    dispatch(post_discount(history))
  }

  const goToPlay = () => {
    if(is_play)  {      
      document.getElementById("myAudio").pause()      
      dispatch(put_data("is_play", false))
    } else {
      document.getElementById("myAudio").play()      
      dispatch(put_data("is_play", true))
    }
  }

  const style = {
      "--landing-discount-bg" : `url(${json?.img_landing_bg})`
  }
  return (
    <div style={style} className="landing-discount">
      <div className="left">
        <div className="left-btn-music">
          <img src={is_play? json.img_music_btn: json.img_music_btn_off} alt="" onClick={goToPlay} />
        </div>
        <div className="left-title">
          <img src={json.img_landing_title} alt="" />
        </div>
      </div>
      <div className="right">
        <div className="right-discount">
          <p>
            { user?.discount_generated? user.discount[0] : '??'}%
          </p>
          <div className="right-discount-bg">
            <img src={json.img_discount_bg} alt="" />
          </div>
        </div>
        <div className={`right-btn-get ${disabled ? "disabled": ""}`}>
          <img src={json.img_get_discount_btn} alt="" onClick={getDiscount}/>
        </div>        
      </div>
    </div>
  )
}

export default LandingDiscount
