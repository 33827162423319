import React, { useState, useEffect } from "react";
import classNames from "classnames";

const Draw = ({ chosenDraws, img_prize_container, img_prize_wrapper, img_rope }) => {
	const [clicked, setClicked] = useState(0);

	const rope_style = {
		"--rope": `url(${img_rope})`
	};

	useEffect(() => {
		setClicked(0);
	}, [chosenDraws]);

	const populateDraw = () => {
		const handleClickItem = (position) => {
			if (clicked === position) setClicked(0);
			else setClicked(position);
		};

		return chosenDraws?.map((dt, idx) => (
			<div key={idx} style={rope_style} className={classNames("draw", `prize-${dt?.position}`)}>
				<div
					className={classNames("item-wrapper", dt?.is_claimed && "disabled")}
					onClick={() => handleClickItem(dt?.position)}
				>
					<img src={img_prize_container} alt="" />
					{dt?.is_frame && <img src={img_prize_wrapper} className="prize-wrapper-img" alt="" />}
					<img src={dt?.image} className="item" alt="" />
					<span className={`item-name ${clicked === dt?.position ? "clicked" : ""}`}>{dt?.item_name}</span>
				</div>
			</div>
		));
	};

	return <div className="draw-container">{populateDraw()}</div>;
};

export default Draw;
