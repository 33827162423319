import React, { useState }  from "react";
import History from "./History";

const Info = ({ event_info = "", historyData, completeJson, txt_event_period, img_history_btn, img_history_btn_off, img_details_btn_off, img_details_btn }) => {
  const [history, setHistory] = useState(false)
	return (
		<>
			<div className="btn-group">
        <div className="tab">
				  <img 
            src={history? img_details_btn_off : img_details_btn} 
            alt="" 
            onClick={() => setHistory(false)}
          />
        </div>
        <div className="tab">				  
				  <img 
            src={history? img_history_btn : img_history_btn_off} 
            alt="" 
            onClick={() => setHistory(true)}
          />
        </div>
			</div>
      {
        history?
        <History data={historyData} {...completeJson} />
        :
        <div className="content">
          <h4 className="event-period">Event Period: {txt_event_period}</h4>
          <div className="rules" dangerouslySetInnerHTML={{ __html: event_info }} />
        </div>
      }
		</>
	);
};

export default Info;
