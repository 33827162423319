import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import "./navigation.scss";

const Navigation = ({ current_pool }) => {
  const { json, prize } = useSelector((state) => state.main, shallowEqual);

  const populateNav = () => {
    const data = prize ?? [];

    return data.map((dt, idx) => (
      <Link
        key={idx}
        to={`/${String(dt?.name).toLowerCase()}`}
        className={classNames("nav", String(dt?.name).toLowerCase() === String(current_pool?.name).toLowerCase() && "active")}
      >
        <img src={dt?.thumbnail} className="open" alt="" />
        <img src={dt?.thumbnail2} className="close" alt="" />
      </Link>
    ));
  };

  return <div className="navigation-cont" style={{ "--img-rope": `url(${json?.img_rope})` }}>{populateNav()}</div>;
};

export default Navigation;
