const main_state = {
	user: false,
	prize: [],
	history: [],
	pool_data: {},
	loader: false,
	discount: false,
	discount_used: false,
	json: false,
	err_msg: false,
	popup_open: false,
	popup_type: false,
	popup_data: false,
	is_play: false,
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data }
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data }
		case "TOGGLE_POPUP":
			return {
				...state,
				popup_open: action.bool,
				popup_type: action.tipe,
				popup_data: action.data,
			}
		case "ADD_POOL_DATA":
			if (action.key in state.pool_data) delete state.pool_data?.[action.key]
			return {
				...state,
				pool_data: { ...state.pool_data, [action.key]: action.data },
			}
		default:
			return state
	}
}

export default main
