import axios from "axios";
import * as main from "./main";
import {
  getUrlParam,
  getLsObject,
  setHash,
  getHash,
  setCache,
  getCache,
  throttle,
  encryptPayload,
  resetThrottle,
  removeParamUrl
} from "helper";

const ls = window.localStorage;

export const set_token = () => {
  return (dispatch) => {
    var token = getUrlParam('token');
    dispatch(main.toggle_loader(true));
    if (getUrlParam('err')) {
      dispatch(main.catch_error(getUrlParam('err')))
      removeParamUrl()
    } else {
      if (token) {
        ls.setItem('token', token);
        window.location.href = `${process.env.REACT_APP_BASE_URL}`;
      }
      else {
        dispatch(main.get_json());
        dispatch(main.check_login())
        dispatch(main.get_pool())
        dispatch(main.get_history())
        dispatch(main.toggle_loader(false));
      }
    }
  };
};

export const get_jwt = (param) => {
  const { accessToken, accountId } = param;

  if (!getCache("jwt_token")) {
    resetThrottle("jwt_token");
  }

  return (dispatch) => {
    // 43200000 = 12 hours
    if (!throttle("jwt_token", 43200000)) {
      dispatch(main.toggle_loader(true));
      axios
        .get(
          `oauth/callback/?access_token=${accessToken}&account_id=${accountId}`
        )
        .then((resp) => {
          setCache("jwt_token", resp.data.token);
          dispatch(get_hash("dlt", param));
        })
        .catch((err) => {
          dispatch(main.catch_error(err?.response?.data?.msg));

          // Send error to analytics
        });
    } else {
      window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    }
  };
};

// HASH
const hash = axios.create({
  baseURL: process.env.REACT_APP_API_HASH,
});

const setKey = (key, param) => {
  if (key === "dlt") {
    // Change to dlt key
    return `event.game.garena.com:dlt:${param.accountId}`;
  } else {
    // Change to hash key
    return `event:api:${key}:${param.accountId}`;
  }
};

export const get_hash = (key) => {
  const param = getLsObject("param");
  const data = {
    key: setKey(key, param),
  };

  const payload = JSON.stringify(data);

  return (dispatch) => {
    const fetch_data = (fetch = true, _) => {
      switch (key) {
        case "dlt":
          if (fetch) {
            dispatch(get_dlt(param.accountId, param.region));
          } else {
            window.location.href = `${process.env.REACT_APP_BASE_URL}`;
          }
          break;
        case "example_hash":
          if (fetch) {
            // Fetch new data from endpoint that match the key
            // dispatch(main.example(key, hashValue));
          }
          break;
        default:
          break;
      }
    };

    hash
      .post(`api/get_hash`, payload)
      .then((resp) => {
        if (resp.data.value === "Not Found") {
          fetch_data(true, resp.data);
        } else if (getHash(key)?.value === resp.data.value) {
          fetch_data(false);
        } else {
          fetch_data(true, resp.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const set_hash = (key) => {
  const param = getLsObject("param");

  // 43200000 = 12 hours
  const data = {
    key: setKey(key, param),
    timeout: 43200000,
  };

  const payload = JSON.stringify(data);

  return (_) => {
    hash
      .post(`api/set_hash`, payload)
      .then((resp) => {
        setHash(key, resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

const dlt = axios.create({
  baseURL: process.env.REACT_APP_API_DLT,
});

const get_dlt = () => {
  const param = getLsObject("param");
  const data = {
    account: param.accountId,
    region: param.region,
    event: "event.game.garena.com",
  };
  const payload = {
    payload: encryptPayload(data, "dlt"),
  };

  return (dispatch) => {
    dlt
      .post(`api/checkin/`, payload)
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        window.location.href = `${process.env.REACT_APP_BASE_URL}`;
      });
  };
};

// const pdc = axios.create({
//   baseURL: process.env.REACT_APP_API_PDC,
// });

// const get_pdc = (key) => {
//   const payload = {
//     key: "test",
//   };

//   return (dispatch) => {
//     pdc
//       .post("api/get_value", payload)
//       .then((resp) => {
//         setPdc(key, resp.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };

// export const set_pdc = (key, value) => {
//   const payload = {
//     key: key,
//     value: value,
//     timeout: 3600,
//   };

//   return (dispatch) => {
//     pdc
//       .post("api/set_value", payload)
//       .then((resp) => {
//         console.log(resp.data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };
