import { createServer } from "miragejs"

import {
    // checkLogin,
    pool,
    poolid,
    history,
    draw,
    checkLoginNotGenerated
} from "./data"

export function makeServer({ environment = "test" } = {}) {
    let server = createServer({
        environment,
        routes() {
            this.urlPrefix = process.env.REACT_APP_API_ENDPOINT;

            this.get("/oauth/check_login/", (schema) => {
                // return checkLogin
                return checkLoginNotGenerated
            })

            this.get("api/pool/:id/", (schema, request) => {
                let { id } = request.params;
                return poolid[id - 1]
            })

            this.get("/api/pool/", (schema) => {
                return pool
            })

            this.get("/api/history/", (schema) => {
                return history
            })

            this.post("/api/draw", (schema) => {
                return draw
            })

            this.passthrough("https://cdngarenanow-a.akamaihd.net/*");
        },
    })

    return server
}