import React from "react";
import { get_date } from "helper";

const History = ({ data, img_history_title }) => {
	const populateHistory = () => {
		return data?.map((dt, idx) => (
			<div key={idx} className="row">
				<div className="col">{idx + 1}.</div>
				<div className="col">{get_date(dt?.purchased_at)}</div>
				<div className="col">{dt.item_name}</div>
			</div>
		));
	};

	return (
		<>
			{/* <div className="title">
				<img src={img_history_title} alt="" />
			</div> */}
			<div className="content">
				<div className="table">
					<div className="thead">
						<div className="row">
							<div className="col">No.</div>
							<div className="col">Date</div>
							<div className="col">Reward</div>
						</div>
					</div>
					<div className="tbody">{populateHistory()}</div>
				</div>
			</div>
		</>
	);
};

export default History;
