import React, { useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from "helper/component/viewport";
import Loader from "helper/component/loader";
import NotFound from "helper/component/404";
import Modals from "partial/Modal";

// Pages
import Home from "pages";
import Landing from "pages/Landing";
import ErrorBoundary from "ErrorBoundary";

// REDUX
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as mainActions from "redux/actions/main";
import * as authActions from "redux/actions/auth";
import Particle from "helper/component/Particle";
import LandingDiscount from "../pages/LandingDiscount";

export const Main = (props) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(authActions.set_token());
		// eslint-disable-next-line
	}, []);
	const { json } = useSelector((state) => state.main, shallowEqual);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<div id="Main" className="main-panel">
				<ErrorBoundary>
					<ViewportRestrict display={false} type="portrait" />
					<Particle />
					<Loader loader={props?.main?.loader} />
					<div className="content-container">
						<Switch>
							<Route exact path="/" component={Landing} />
							<Route exact path="/landing" component={LandingDiscount} />
							<Route exact path="/:id" component={Home} />
							<Route component={NotFound} />
						</Switch>
					</div>
					<Modals {...props} />
					{
						json &&
						<audio id="myAudio" loop>
							<source src={json.url_bg_music} type="audio/mpeg" />
						</audio>
					}
				</ErrorBoundary>
			</div>
		</Router>
	);
};

const mapStateToProps = (state) => ({
	main: state.main
});

const mapDispatchToProps = (dispatch) => ({
	actionsMain: bindActionCreators(mainActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
