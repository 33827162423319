import React from "react"

const Success = ({ item_name, image, close, img_prize_wrapper, img_congratulations_title, img_ok_btn }) => {
	const wrapperStyle = {
		"--img-frame": `url(${img_prize_wrapper})`,
	}

	return (
		<>
			<div className="title">
				<img src={img_congratulations_title} alt="" />
			</div>
			<div className="content">
				<div className="image" style={wrapperStyle}>
					<img src={image} alt="" />
				</div>
				<div className="message">
					<span>You've got {item_name}!</span>
					<span className="sub-message">Reward sent to your in-game mail!</span>
				</div>
				<img className="button" onClick={close} src={img_ok_btn} alt="" />
			</div>
		</>
	)
}

export default Success
