import React from "react";
import { Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import "./styles/landing.scss";

const Landing = () => {
	const { prize, json, user: { user } } = useSelector((state) => state.main, shallowEqual);

	if (user && !user?.discount_generated) {
		return <Redirect to='/landing' />;
	} else if (user?.discount_generated && prize && prize?.length > 0) {
		return <Redirect to={`/${prize?.[0]?.name.toLowerCase()}`} />;
	} else {
		return (
			<div className="landing">
				<img
					className="animate__animated animate__pulse animate__infinite animate__slow"
					src={json.img_title}
					alt=""
				/>
			</div>
		);
	}
};

export default Landing;
