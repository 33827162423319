import React from "react";
import { shallowEqual, useSelector } from "react-redux";

import User from "./User";
import Draw from "./Draw";
import Button from "./Button";

const Content = ({ current_pool }) => {
	const { user, json, pool_data, discount_used } = useSelector((state) => state.main, shallowEqual);

	const currentName = String(current_pool?.name).toLowerCase();
	const chosenDraws = pool_data[currentName]?.loot;

	const multiplier = user?.user?.ticket_to_voc ?? 8;
	const draw_price = pool_data[currentName]?.draw_price ?? 0;
	const priceInTicket = draw_price;
	const priceInVoucher = multiplier * priceInTicket;

	return (
		<div className="content-wrapper">
			<User {...user?.user} {...json} />
			<Draw current_pool={current_pool} chosenDraws={chosenDraws} {...json} />
			<Button
				{...user}
				discount_used={discount_used}
				current_pool={current_pool}
				priceInVoucher={priceInVoucher}
				priceInTicket={priceInTicket}
				chosenDraws={chosenDraws}
				{...json}
			/>
		</div>
	);
};

export default Content;
