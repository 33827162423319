import React, { useState } from "react";

const HEADER_VOUCHER = "https://cdngarenanow-a.akamaihd.net/webid/AOV/shrine/Pop Up/voucher.png";

const ConfirmLeft = (props) => {
	const [skip, setskip] = useState(false);
	return (
		<>
			<div className="content">
				<div className="question">
					<span>Need {props?.priceInTicket} </span>
					<span>
						<img src={props?.img_token} alt="" />
					</span>
					<span>more.</span>
				</div>
				<div className="question">
					<span>Spend {props?.priceInVoucher} </span>
					<span>
						<img src={HEADER_VOUCHER} alt="" />
					</span>
					<span> to draw?</span>
				</div>
				<label htmlFor="confirm_draw">
					<input onClick={() => setskip(!skip)} type="checkbox" name="confirm_draw" id="confirm_draw" />
					Don't ask again 
				</label>
				<img
					className="button"
					onClick={() => {
						if (skip) window.localStorage.setItem("skip_confirm", true);
						props?.draw();
					}}
					src={props?.img_ok_btn}
					alt=""
				/>
			</div>
		</>
	);
};

export default ConfirmLeft;
