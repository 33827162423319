const hash_state = {
	login: false,
}

const hash = (state = hash_state, action) => {
	switch (action.type) {
		case "PUT_DATA_HASH":
			return { ...state, [action.key]: action.data }
		default:
			return state
	}
}

export default hash
